<template lang="pug">
  div
    v-row
      v-col(cols="12" lg="3")
        v-text-field(label="Name" v-model="name" :error-messages="errors.name")
      v-col(cols="12" lg="3")
        v-text-field(label="Purok" v-model="purok" :error-messages="errors.purok")
      v-col(cols="12" lg="3")
        v-text-field(label="Brgy" v-model="brgy" :error-messages="errors.brgy")
      v-col(cols="12" lg="3")
        v-text-field(label="City/Municipality" v-model="city" :error-messages="errors.city")
      v-col(cols="12" lg="3")
        v-text-field(label="Province" v-model="province" :error-messages="errors.province")
      v-col(cols="12" lg="3")
        v-text-field(label="Contact No 1" v-model="contactno1" :error-messages="errors.contactno1")
      v-col(cols="12" lg="3")
        v-text-field(label="Contact No 2" v-model="contactno2" :error-messages="errors.contactno2")
</template>
<script>

const inputVars = () => ({
  id: null,
  name: null,
  purok: null,
  brgy: null,
  city: null,
  province: null,
  contactno1: null,
  contactno2: null,
})

export default {
  name: 'HericaClientInfoForm',
  props: {
    value: {
      type: Object,
      default: inputVars,
    },
    errors: {
      type: Object,
      default: () => ({ }),
    },
  },
  data () {
    return {
      ...inputVars(),
    }
  },
  watch: {
    ...Window.watchInputVars(inputVars(), function () {
      this.updateInput()
    }, ['id']),

  },
  methods: {
    updateInput () {
      const data = this.getInputValues()
      this.$emit('input', data)
    },
    getInputValues () {
      return Object.keys(inputVars())
        .reduce((result, key) => {
          result[key] = this[key]
          return result
        }, {})
    },
  },
}
</script>